@font-face {
  font-family: AtypText;
  font-weight: 200;
  src: url("./_metronic/materialUIThemeProvider/fonts/AtypText-Medium.ttf");
}

@font-face {
  font-family: AtypText;
  font-weight: 300;
  src: url("./_metronic/materialUIThemeProvider/fonts/AtypText-Medium.ttf");
}

@font-face {
  font-family: AtypText;
  font-weight: 400;
  src: url("./_metronic/materialUIThemeProvider/fonts/AtypText-Medium.ttf");
}

@font-face {
  font-family: AtypText;
  font-weight: 500;
  src: url("./_metronic/materialUIThemeProvider/fonts/AtypText-Semibold.ttf");
}

@font-face {
  font-family: AtypText;
  font-weight: 600;
  src: url("./_metronic/materialUIThemeProvider/fonts/AtypText-Semibold.ttf");
}

@font-face {
  font-family: AtypText;
  font-weight: 700;
  src: url("./_metronic/materialUIThemeProvider/fonts/AtypText-Semibold.ttf");
}

* {
  // font-family: "Open Sans", Helvetica, sans-serif;
  font-family: "AtypText";
}

body {
  background: #f5f8fa !important;
}

// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";

@import "~react-image-gallery/styles/scss/image-gallery.scss";
@import "~react-image-gallery/styles/css/image-gallery.css";

@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";

// Default Layout Skins
@import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
@import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
@import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
@import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";

// for images gallery (in product view page)

.image-gallery-thumbnails {
  padding: 0;
}

.image-gallery-thumbnails .image-gallery-thumbnails-container {
  text-align: left;
}

.image-gallery-thumbnail {
  border-radius: 4px;
  width: 175px !important;
  height: 80px !important;
  margin-right: 15px;
}

.image-gallery-thumbnail:last-child {
  margin-right: 0;
}

.image-gallery-thumbnail-inner {
  width: 175px !important;
  height: 80px !important;
}

.image-gallery-thumbnail-inner img {
  object-fit: cover;
  width: 175px !important;
  height: 80px !important;
}

.image-gallery-thumbnail.active,
.image-gallery-thumbnail:focus,
.image-gallery-thumbnail:hover {
  border: none;
}

.image-gallery-slide-wrapper {
  position: relative;

  &.left,
  &.right {
    display: inline-block;
    width: calc(100% - 310px); // 100px + 10px for margin

    @media (max-width: $ig-small-screen) {
      width: calc(100% - 87px); // 81px + 6px for margin
    }
  }

  &.image-gallery-rtl {
    direction: rtl;
  }
}


.image-gallery-thumbnails-wrapper {
  position: relative;

  &.thumbnails-swipe-horizontal {
    touch-action: pan-y;
  }

  &.thumbnails-swipe-vertical {
    touch-action: pan-x;
  }

  &.thumbnails-wrapper-rtl {
    direction: rtl;
  }

  &.left,
  &.right {
    display: inline-block;
    vertical-align: top;
    width: 100px;

    @media (max-width: $ig-small-screen) {
      width: 81px; // 75px + 6px for border
    }

    .image-gallery-thumbnails {
      height: 100%;
      width: 100%;
      left: 0;
      padding: 0;
      position: absolute;
      top: 0;

      .image-gallery-thumbnail {
        display: block;
        margin-right: 0;
        padding: 0;

        +.image-gallery-thumbnail {
          margin-left: 0;
          margin-top: 2px;
        }

      }

    }
  }

  &.left,
  &.right {
    margin: 0 45px;

    @media (max-width: $ig-small-screen) {
      margin: 0 3px;
    }
  }
}


// fix material ui icons in safari
.MuiSvgIcon-root {
  transform: scale(1);
}

// Header Skins
.kt-header-base-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/light.scss";
}

.kt-header-base-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/base/dark.scss";
}

// Header Menu Skins
.kt-header-menu-light {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/light.scss";
}

.kt-header-menu-dark {
  @import "./_metronic/_assets/sass/global/layout/header/skins/menu/dark.scss";
}

// Brand Skins
.kt-brand-dark {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/dark.scss";
}

.kt-brand-light {
  @import "./_metronic/_assets/sass/global/layout/brand/skins/light.scss";
}

// Aside skins
.kt-aside-dark {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/dark.scss";
}

.kt-aside-light {
  @import "./_metronic/_assets/sass/global/layout/aside/skins/light.scss";
}

// Web Icon fonts
// @import "~socicon/css/socicon.css";
// @import "~@fortawesome/fontawesome-free/css/all.min.css";
// @import "./_metronic/_assets/plugins/line-awesome/css/line-awesome.css";
// @import "./_metronic/_assets/plugins/flaticon/flaticon.css";
// @import "./_metronic/_assets/plugins/flaticon2/flaticon.css";

@-webkit-keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUpContent {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -5%, 0);
    transform: translate3d(0, -5%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUpContent {
  -webkit-animation-name: fadeInUpContent;
  animation-name: fadeInUpContent;
}

@media screen and (max-width: 500px) {
  .MuiTablePagination-root {
    width: 100%;
  }

  .MuiToolbar-root {
    justify-content: space-between;
  }

  .MuiTablePagination-spacer,
  .MuiTablePagination-spacer+.MuiTablePagination-caption {
    display: none;
  }

  .MuiTablePagination-select {
    padding-left: 0 !important;
  }

  .MuiTablePagination-toolbar,
  .MuiToolbar-gutters {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .MuiTablePagination-selectRoot {
    margin-left: 0 !important;
    margin-right: 10px !important;
  }
}

//Redesign

.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item>.kt-menu__link .kt-menu__link-text {
  color: #000000 !important;
}

.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item.kt-menu__item--here>.kt-menu__link .kt-menu__link-text {
  color: rgb(0, 90, 90) !important;
}

.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item>.kt-menu__link .kt-menu__link-text {
  color: #000000 !important;
}

.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--here>.kt-menu__link .kt-menu__link-text,
.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item.kt-menu__item--active>.kt-menu__link .kt-menu__link-text,
.kt-header-base-light .kt-header .kt-header-menu .kt-menu__nav>.kt-menu__item:hover>.kt-menu__link .kt-menu__link-text {
  color: rgb(0, 90, 90) !important;
}

.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item:hover:not(.kt-menu__item--here):not(.kt-menu__item--active)>.kt-menu__link .kt-menu__link-text,
.kt-header-menu-light .kt-header-menu .kt-menu__nav>.kt-menu__item .kt-menu__submenu>.kt-menu__subnav>.kt-menu__item.kt-menu__item--hover:not(.kt-menu__item--here):not(.kt-menu__item--active)>.kt-menu__link .kt-menu__link-text {
  color: rgb(0, 90, 90) !important;
}

.MuiTableCell-root a {
  color: rgb(0, 90, 90) !important;
}

.kt-notification .kt-notification__item .kt-notification__item-icon i {
  color: rgb(0, 90, 90) !important;
}

.react-multi-carousel-dot-list button {
  background-color: rgb(0, 90, 90) !important;
}

.MuiButton-label {
  svg {
    color: rgb(0, 90, 90) !important;
  }
}

.kt-notification {
  .kt-notification__item-title {
    color: rgb(0, 90, 90) !important;
  }
}

.kt-menu-cart-icon {
  color: rgb(0, 90, 90) !important;
}

.kt-menu__link {
  .kt-menu__link-text {
    color: rgb(0, 90, 90) !important;
  }
}

.pac-target-input:focus {
  box-shadow: rgb(0, 90, 90) 0px 0px 0px 2px inset !important;
}

.kt-header-base-light .kt-header .kt-header__topbar .kt-header__topbar-item.kt-header__topbar-item--user .kt-header__topbar-user .kt-header__topbar-username {
  color: rgb(0, 90, 90);
}

a {
  color: rgb(0, 90, 90) !important;
}

.kt-link {
  color: rgb(0, 90, 90) !important;
}

.kt-footer-span {
  color: #5e6278 !important;
}

.kt-footer-box {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-left: 25px;
  padding-right: 25px;
  padding-top: 20px;
  background-color: #ebebeb;
  height: 140px;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: rgb(0, 90, 90) !important;
}

.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
  background-color: #78dc78 !important;
}

.kt-badge.kt-badge--danger {
  background: #78dc78;
}

.MuiButtonGroup-groupedOutlinedHorizontal {
  color: rgb(0, 90, 90) !important;
  border-color: rgb(0, 90, 90) !important;
}

.kt-login.kt-login--v1 .kt-login__aside .kt-login__info .kt-login__menu>a {
  color: #fff !important;
}

.btn-primary {
  background-color: rgb(0, 90, 90) !important;
}

.productView-btn-group {
  svg {
    color: #fff !important;
  }
}

.kt-scrolltop {
  background: rgb(0, 90, 90) !important;
}

.color09090 {
  color: rgb(0, 90, 90) !important;
}

// Slider
 .slick-track {
   position: relative;
   top: 0;
   left: 0;
   padding: 0 !important;

   display: block;
   margin-left: 0;
   margin-right: 0;
 }

.slick-center {
  transform: scale(1.1)
}

.slick-slide {
  transition: 0.5s all ease;
  opacity: 0.5;
}

.slick-active {
  padding: 0;
  opacity: 1;
}

// .slick-slide:not(.slick-active) {
//   margin: 20px 0;
// }

// .custom-dots {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   margin: 0;
//   padding: 0;
// }

.slick-dots {
  bottom: -35px !important;
  @media screen and (max-width: 500px) {
    bottom: -30px !important;
  }

  &>li {
    width: 12px !important;
    height: 12px !important;
    margin: 0 15px !important;

    &>button::before {
      font-size: 0;
      width: 8px !important;
      height: 8px !important;
      border-radius: 12px !important;
      background-color: #005A5A;
    }
  }

  &>li.slick-active {
    width: 40px !important;
    height: 12px !important;
    margin: 0 15px !important;

    &>button::before {
      font-size: 0;
      width: 40px !important;
      height: 8px !important;
      border-radius: 12px !important;
      background-color:#005A5A;
    }
  }
}

.custom-dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  border: none;
  background-color: red;
  margin-right: 30px;
  //margin-top: -35px;
  cursor: pointer;
}


.custom-dot-active {
  width: 100%;
  height: 100%;
  border-radius: 20px;
  border: none;
  background-color: blue;
  margin-right: 30px;
  //margin-top: -35px;
  cursor: pointer;
}

.iconContent {
  display: flex;
  width: 120px;
  justify-content: space-between;
  align-items: center;
}

// .custom-dot {
//   width: 5px;
//   height: 5px;
//   border-radius: 20px;
//   border: none;
//   background-color: #005A5A;
//   margin-right: 30px;
//   margin-top: -35px;
//   cursor: pointer;
// }

// .custom-dots button:last-child {
//   margin-right: 0;
// }

// .custom-dots button.slick-active {
//   background-color: #000;
// }
